@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: linear-gradient(-45deg, rgb(248 250 252), rgb(254 242 242), rgb(255 251 235), rgb(240 253 250), rgb(245 243 255));
	background-size: 400% 400%;
	animation: gradient 15s ease-in-out infinite;
	height: 100vh;
  opacity: 1;
  transition-duration: 0.7s;
  transition-property: opacity;
}

#about-img{
  border-radius: 50%;
}

.social-icons a{
  font-size:32px;
}

.navbar {
  animation: fade-in 1s ease-in;
}

#about{
  animation: fade-in 2s ease-in;
}

#projects{
  animation: fade-in 3s ease-in;
}

#contact{
  animation: fade-in 4s ease-in;
}

@layer utilities{
  .text-balance {
    text-wrap: balance;
  }
}

  @keyframes fade-in{
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }